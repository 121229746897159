#spreadsheet_tbl{
    tbody{
        th{
            font-weight: 300 !important;
        }
    }
}
.custom-overflow{
    height: 600px !important;
    overflow-y: scroll;
}