#estatePage{
    table thead th {
        font-weight: 100 !important;
        color: rgba(0, 0, 0, 0.87) !important; 
        line-height: 1.5rem;
        display: table-cell;
        padding: 16px;
        text-align: left;
        font-family: Poppins;
        font-weight: 400;
        line-height: 1.43;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        vertical-align: inherit;

        
        &.fs-1{ 
            font-size: 0.875rem !important;
        }
    }

    table tbody th,td {
        font-weight: 400 !important;
        color: rgba(0, 0, 0, 0.87) !important; 
        line-height: 1.5rem;
        display: table-cell;
        padding: 16px;
        text-align: left;
        font-family: Poppins;
        font-weight: 400;
        line-height: 1.43;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        vertical-align: inherit;

        &.MuiButtonBase-root, .MuiListItemSecondaryAction-root{
            font-weight: 400 !important;
            color: rgba(0, 0, 0, 0.87) !important; 
            line-height: 1.5rem;
            display: table-cell;
            padding: 16px;
            text-align: left;
            font-family: Poppins;
            font-weight: 400;
            line-height: 1.43;
            border-bottom: 1px solid rgba(224, 224, 224, 1);
            vertical-align: inherit; 
            
            &.fs-1{ 

                font-size: 0.875rem !important;
            }

        }

        &.fs-1{ 
            font-size: 0.875rem !important;
        }
    }
   
    @include media-breakpoint-up(lg) {
        .estate-page-holder{
            // border-style: solid;
            // border-color: rgb(9, 0, 128);
            // width: 75%;
             width: 100%;
        }  
        .notification-icon {
            position: absolute;
            top: 0px;
            right: 0px;
            // width: 15px;
            // height: 15px;
            // border-radius: 50%;
            // background-color: rgb(63, 118, 213);
            // border: 2px solid white;
        }
    }
    @include media-breakpoint-down(md) {
        .estate-page-holder{
            // border-style: solid;
            // border-color: green;
            width: 100%;
        }  
    }
}