.filter-box{
    height: 100% !important;
}
.search-button{
    height: 100% !important;
}
table thead {
    th{
        font-weight: 100 !important;
    }
    tr{
        font-weight: 400 !important;
    }
    
}
.timeline.timeline-6:before {
    left: 107px !important;
}
.timeline.timeline-6 .timeline-item .timeline-label {
    width: 105px !important;
}