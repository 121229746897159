.login-label{
    font-size: 15px !important;
    font-weight: 600 !important;
}
.login-left-side-bg{
    background-color: white;
}
.login-left-side-container{
    padding: 65px;
    color: black;
    p{
        font-weight: 300 !important;
    }
}
.login-right-side-content{
    background-color: #eef0f8;
}

.login-sign-in{
    font-size: 29px !important;
}
.login-sign-in-info{
    font-size: 18px !important;
}
.login-inputs{
    height: 65px !important;
    background-color: #FFFF !important; 
    border-color: #FFFF !important;
    font-size: 13px !important;
}
.notif-warning{
    margin-top: 0.5rem !important;
}

.max-height-image{
    max-height: 300px;
}

@media (min-width: 0px) and (max-width: 414px){
    .login-right-side-container{
        width: 100% !important;
    }
    .login-content{
        margin-top: 50px;
    }
    .login-forgot-password{
        font-size: 12px !important;
    }
    .login-left-side-container{
        padding: 0px;
        color: black;
    }
    .max-height-image{
        max-height: 240px;
    }
    .image-top{
        display:flex !important;
        justify-content: center !important;
    }
    .margin-button-sign{
        margin-bottom: 50px !important;
    }
}

@media (min-width: 415px) and (max-width: 768px){
    .login-right-side-container{
        width: 100% !important;
    }
    .login-forgot-password{
        font-size: 15px !important;
    }
    .no-margin-bottom{
        margin-bottom: 0px !important;
    }
    .ml-mr{
        margin-left: 70px;
        margin-right: 70px;
    }
    .login-left-side-container{
        padding: 65px;
        padding-top: 0px;
        padding-bottom: 10px;
    }
    .image-top{
        display:flex !important;
        justify-content: center !important;
    }
    .login-content{
        margin-top: 70px;
        margin-bottom: 70px;
    }
}

@media (min-width: 992px){
    .login-right-side-container{
        width: 85% !important;
    }
    .login-forgot-password{
        font-size: 15px !important;
    }
}