#subscription-page{
    
    .MuiStepConnector-vertical{
        display: none !important;
    }

    .MuiStepLabel-iconContainer {
        margin-right: 1.4rem !important;
    }

    .MuiStep-root.MuiStep-vertical{
        margin-bottom: 50px !important;
    }

    .MuiStepLabel-iconContainer{
        .makeStyles-active-10{
            h5{
                color: #3699FF;
            }
        }
    }

    .btnSubmit{
        background-color: #1BC5BD !important;
        color: white;
    }

}