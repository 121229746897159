
#timelinePage {
    .card {
        .custom-list {
            height: 70vh;
            overflow-y: auto;
        }
    }
    #frmTimeline {
        .timeline {
            overflow-y: auto;
            
            .item {
                padding: 0px 50px 0px 50px; 
    
                &.non-factual {
                    flex-direction: row-reverse !important;
                }
    
                &.factual {
                    flex-direction: row !important;
                }
                .timeline-paper {
                    background: #F3F6F9;
                    border-radius: 8px !important;
                    min-height: 150px;
                    padding: 15px 15px 0 15px;
                } 
                @media print {
                    &:last-child {
                      margin-bottom: 50% !important;
                    }
                    .timeline-paper {
                        padding: 15px 15px 0 15px;
                    }
                  }
                         
    
                @media only screen and (max-width: 768px) {
                    &.responsive {
                        
                        &::before {
                            content: revert !important;
                            padding: 0px !important;
                        }
    
                        flex-direction: row !important;
                        .response-separator {
                            display: none;
                        }
                    }
                }
            }
        }
    } 

    .fab-print{
        position: fixed;
        bottom: 5vh;
        right: 15vh;
    }
}
