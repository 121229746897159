#expenseTracker {
    .sheet-button {
        width: 100%;
        border-radius: 0.42rem;
        color: #3F4254;
        
        &.MuiButton-label{
            text-align: start !important;
        }

        &.active {
            transition: all 0.15s ease;
            background-color: #F3F6F9;
            color: #3699FF;
        }
    }

    .btn {
        text-align: center;
        &.switch {
            &.type-a {
                background-color: #ffc107 !important;
            }
        }
    }

   
} table{
    .purple-cell {
        background: #6f42c1 !important;
        color: #fff;
    }
    .orange-cell {
        background: #fd7e1482; 
    }
    .blue-cell {
        background: #007bff7d; 
    }
    .green-cell {
        background: #28a74575; 
    }
}