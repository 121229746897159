#childrenPage {
    .btn-create {
        background: #DBEDFF;
        color: #3699FF;
    }
    
    .text-search {
        height: 35px;
        width: 200px;
    }

    
    @include media-breakpoint-up(lg) {
        .children-content-holder{
            // border-style: solid;
            // border-color: rgb(12, 110, 43);
            flex: 0 0 75%;
            max-width: 73%;
        }
    }
    
    @include media-breakpoint-up(xl) {
        .children-content-holder{
            max-width:66%;
        }
    }

    @include media-breakpoint-down(md) {
        .children-content-holder{
            width: 100%;
        }
    }
    @include media-breakpoint-down(sm) {
        .children-content-holder{
            width: 100%;
        }
    }
    
}